module services {
    export module logistics {
        export class commercialInvoiceService implements interfaces.logistics.ICommercialInvoiceService {
            static $inject = ["$resource", "ENV", "$http", "generalService", "$timeout"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig, private $http: ng.IHttpService, public generalService: interfaces.applicationcore.IGeneralService,
                private $timeout: ng.ITimeoutService) {
            }


            processDocumentsToExcel(ocrToInvoiceFileData: interfaces.logistics.OCRToInvoiceFileData): ng.IPromise<void>{
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + 'CommercialInvoice/ProcessDocumentsToExcel';

                return this.$http({
                    method: 'POST',
                    responseType: 'arraybuffer',
                    url: url,
                    data: ocrToInvoiceFileData
                }).then((response) => {
                    var contentDisposition = response.headers()['content-disposition'];
                    var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim() || 'GTSInvoice.xlsx';

                   // var filename = response.headers()['x-filename'] || 'GTSInvoiceDetailDownload.xlsx';
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }

                    this.$timeout(() => {
                        this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                    });
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }

            GetSalesOrderAllocationLines(): ng.resource.IResourceClass<interfaces.logistics.ICommercialInvoice> {

                return this.$resource<interfaces.logistics.ICommercialInvoice>(this.ENV.DSP_URL + "Consignment/GetSalesOrderAllocationLines", {
                }, {
                        query: {
                            method: 'POST',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

            GetCommercialInvoiceLineSKUDisplayListExcel(comId: number): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + 'CommercialInvoice/GetCommercialInvoiceLineSKUDisplayListExcel?comId=' + comId;

                return this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: url,
                }).then((response) => {
                    var contentDisposition = response.headers()['content-disposition'];
                    var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim() || 'GTSInvoiceDetailDownload.xlsx';

                   // var filename = response.headers()['x-filename'] || 'GTSInvoiceDetailDownload.xlsx';
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }

                    this.$timeout(() => {
                        this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                    });
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }

            get(comId: number, consignmentId?: number, manifestId?: number, isCostInvoice?: boolean): ng.resource.IResourceClass<interfaces.logistics.ICommercialInvoice> {
                return this.$resource<interfaces.logistics.ICommercialInvoice>(this.ENV.DSP_URL + "CommercialInvoice/Get", {
                    comId: comId,
                    consignmentId: consignmentId,
                    manifestId: manifestId,
                    isCostInvoice: isCostInvoice
                }, {
                        get: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

            getSupplierDefault(ownerEntityId: number, id: number, isInbound: boolean, isCostInvoice: boolean): ng.resource.IResourceClass<interfaces.logistics.ICommercialInvoice> {
                return this.$resource<interfaces.logistics.ICommercialInvoice>(this.ENV.DSP_URL + "CommercialInvoice/GetSupplierDefault", {
                    ownerEntityId: ownerEntityId,
                    id: id,
                    isInbound: isInbound,
                    isCostInvoice: isCostInvoice
                }, {
                        get: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

            getCustomerDefaultCurrency(customerId: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "CommercialInvoice/GetCustomerDefaultCurrency", {
                    customerId: customerId,
                }, {
                        get: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

            generateCostInvoices(consignmentId?: number, manifestId?: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CommercialInvoice/GenerateCostInvoices", {
                    consignmentId: consignmentId,
                    manifestId: manifestId
                });
            }

            CreateCommercialInvoice(consignmentId: number, supplierId: number, customerId: number,invoiceDate:  string,invoiceCurrencyId: number, customInvoiceNumber: string, invoiceCounterId?: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {

                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CommercialInvoice/CreateCommercialInvoice", {
                    consignmentId: consignmentId,
                    supplierId: supplierId,
                    customerId: customerId,
                    invoiceDate : invoiceDate,
                    invoiceCurrencyId : invoiceCurrencyId,
                    customInvoiceNumber: customInvoiceNumber,
                    invoiceCounterId: invoiceCounterId
                });
            }

            save(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CommercialInvoice/Save", {
                });
            }

            delete(comId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CommercialInvoice/Delete", {
                    comId: comId
                });
            }

            changeStatus(costInvoiceId: number, newStatusId: number, version: number, comments: string, currentStatusId?: number, rejectionReason?: string, rejectionId?: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CommercialInvoice/ChangeStatus", {
                    costInvoiceId: costInvoiceId,
                    newStatusId: newStatusId,
                    version: version,
                    comments: comments,
                    rejectionReason: rejectionReason,
                    rejectionId: rejectionId,
                    currentStatusId: currentStatusId
                });
            }

            calculatePaymentDueDate(isInbound: boolean, comId?: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CommercialInvoice/CalculatePaymentDueDate", {
                    isInbound: isInbound,
                    comId: comId
                });
            }
            
            generateFECNumber(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CommercialInvoice/GenerateFECNumber", {
                    id: '@id'
                });
            }

            //Takes Consignment Id and Commercial Id
            refreshComments(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CommercialInvoice/RefreshComments", {
                    conId: '@conId',
                    comId: '@comId'
                });
            }

            //Takes Commercial Id
            refreshInstructions(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CommercialInvoice/RefreshInstructions", {
                    comId: '@comId'
                });
            }

            refreshSundryItems(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CommercialInvoice/RefreshSundryItems", {
                    comId: '@comId',
                    conId: '@conId',
                });
            }

            refreshFromSalesOrder(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CommercialInvoice/RefreshFromSalesOrder", {
                    comId: '@comId'
                });
            }


            refreshFromDeliveryInstruction(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CommercialInvoice/RefreshFromDeliveryInstruction", {
                    comId: '@comId'
                });
            }
            
            getDILookupSearchByConsignmentId(consignmentId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CommercialInvoice/GetDILookupSearchByConsignmentId", {
                    consignmentId: consignmentId
                });
            }

            //This does not include Cost Invoices.
            getCommercialInvoiceForConsignment(consignmentId: number): ng.resource.IResourceClass<interfaces.logistics.ICommercialInvoice>
            {
                return this.$resource<interfaces.logistics.ICommercialInvoice>(this.ENV.DSP_URL + "CommercialInvoice/GetCommercialInvoiceForConsignment", {
                    consignmentId: consignmentId
                });
            }

            updateCommercialInvoiceDate(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CommercialInvoice/UpdateCommercialInvoiceDate", {
                });
            }

            commercialInvoiceTradeAgreementWizard(comId: number, declarationCountryId: number, countryGroupId?: number, countryGroupOriginId?: number, preferenceCodeId?: number, overideExisting?: boolean, updateProductMaster?: boolean): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CommercialInvoice/CommercialInvoiceTradeAgreementWizard", {
                    comId: comId,
                    declarationCountryId: declarationCountryId,
                    countryGroupId: countryGroupId,
                    countryGroupOriginId: countryGroupOriginId,
                    preferenceCodeId: preferenceCodeId,
                    overideExisting: overideExisting,
                    updateProductMaster: updateProductMaster,
                });
            }

            commercialInvoiceConvertUoM(isConvertOrderUnitofMeasure: boolean, comId: number, converttoUnitofMeasureId?: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CommercialInvoice/CommercialInvoiceConvertUoM", {
                    comId: comId,
                    isConvertOrderUnitofMeasure: isConvertOrderUnitofMeasure,
                    converttoUnitofMeasureId: converttoUnitofMeasureId
                });
            }

            containCertificate(comId: number): ng.resource.IResourceClass<ng.resource.IResource<{ value: boolean }>> {
                return this.$resource<ng.resource.IResource<{ value: boolean }>>(this.ENV.DSP_URL + "CommercialInvoice/ContainCertificate", {
                    comId: comId
                });
            }

            uploadFromExcel(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CommercialInvoice/UploadFromExcel", {});
            }
        }
    }
    angular.module("app").service("commercialInvoiceService", services.logistics.commercialInvoiceService);
}